import React from "react"
import { useIntl } from "gatsby-plugin-intl"

//Particles
import Particles from "react-particles-js"
import aboutParticles from "../assets/aboutParticles.json"

// Components
import Layout from "../components/layout"
import Seo from "../components/seo"

//Styles
import aboutStyles from "./about.module.scss"

export default () => {
  const intl = useIntl()
  return (
    <Layout>
      <Seo title={intl.formatMessage({ id: "about__title" })} />
      <section className={aboutStyles.about}>
        <section className={aboutStyles.text}>
          <h1 className={aboutStyles.title}>
            {intl.formatMessage({ id: "about__title" })}
          </h1>
          <p>{intl.formatMessage({ id: "about__content_1" })}</p>
          <br />
          <p>{intl.formatMessage({ id: "about__content_2" })}</p>
        </section>
        <Particles className={aboutStyles.particles} params={aboutParticles} />
        <section className={aboutStyles.right}>
          <div></div>
          <div></div>
          <div></div>
        </section>
      </section>
    </Layout>
  )
}
